.feed-wrapper {
    min-height: 300px;
}
.feed-control {
    margin-top: -15px;
    display: flex;
    flex-direction: row;
}

.filter-button {
    /* background-color: red; */
    margin-right: 5px;
    height: 55px;
    width: 60px;
    background-color: #fff;
    border: 1px solid #EBEDF5;
    border-radius: 12px;
    color: var(--main-color);
    float:left;
    
}


.filter-button:hover{
    background-color: #fff;
    border: 1px solid #EBEDF5;
    border-radius: 12px;
    color: var(--main-color);
}

.filter-button:active{
    background-color: #fff;
    border: 1px solid #EBEDF5;
    border-radius: 12px;
    color: var(--main-color);
}

.dropdown-toggle::after {
    display: none;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle  {
    background-color: #fff;
    border: 1px solid #EBEDF5;
    border-radius: 12px;
    color: var(--main-color);
}

.btn-primary {
    background-color: #fff !important;
    border: 1px solid #EBEDF5 !important;
    color: var(--main-color) !important;
}

.dropdown-item {font-size: 14px;}

.feed-control svg {
    position: absolute;
    margin-top: 18.5px;
    margin-left: 80px;
}

.search-feed {
    height: 55px;
    /* margin-left: 15px; */
    background-color: #fff;
    border: 1px solid #DDDDE0;
    border-radius: 12px;
    min-width: 250px;
    max-width: 635px;
    width: 100%;
    outline: none;
    padding: 15px;
    padding-left: 45px;
}

.search-feed::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b1b2bc;
    opacity: 1;
    /* Firefox */
}

.feed {
    margin-top: 40px;
}

.feed h2 {
    font-size: 24px;
    font-weight: 700;
    color: #11142D !important;
}

/* Specific post */
.post {
    margin-top: 30px;
}
.post .date {
    color: #808191;
    font-size: 14px;
    font-weight: normal;
}

.post-block {
    background-color: #FFFFFF;
    border: 1px solid #DDDDE0;
    border-radius: 12px;
    max-width: 700px;
    min-width: 250px;
    min-height: 150px;
    padding:30px;
}

.post-content {
    text-align: center;
}

.post-information {
    margin-top: 15px;
}

.post-block .tag {
    font-size: 14px;
    font-weight: normal;
    color: #808191;
    margin-bottom: 0px;;
    margin-top: 0px;
}

.post-block .title {
    margin-top: 0px;
    margin-bottom: 0px;;
    color: #11142D;
    font-size: 18px;
    font-weight: 700;
}

.post-block .description {
    font-size: 12px;
    font-weight: normal;
    color: #808191;
    margin-top: 5px;
    margin-bottom: 0px;;
}


.post-content img {
    border-radius: 8px;
    cursor: pointer;
    width:400px;
}

.post-content video {
    border-radius: 8px;
    height:300px;
    width: 400px;
}

.post-content .pdf {
    border-radius: 8px;
    width:400px;
    height:600px;
}

.ril-outer {
    z-index: 5;
}

/* #image-view {
    top:0;
    left:0;
    z-index: 1000000;
    position: fixed;
    width:100%;
    height:100%;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
    padding-top:30px;
    padding-bottom:30px;
}

#image-view img {
    width: auto;
    height: auto;
    max-height:100%;
    max-width: 100%;
    border-radius: 3px;
}

#image-view svg {
    color: #FFF !important;
    position: absolute;
    right:40px;
    top:40px;
    cursor: pointer;
} */


.ReactModalPortal {
    z-index: 9999;
}


@media only screen and (max-width: 992px) {
    .feed {
        max-width: 700px;
        margin: auto;
        margin-top: 40px;
    }

    .feed-control {
        max-width: 700px;
        margin: auto;
        margin-top: -15px;
    }
    .post-block {
        margin: auto !important;
    }
}


@media only screen and (max-width: 455px) {
    .post-content img {
        width:350px;
    }
    
    .post-content video {
        max-width: 350px;
    }
    
    .post-content .pdf {
        width:350px;
    }
}

@media only screen and (max-width: 390px) {
    .post-content img {
        width:300px;
    }
    
    .post-content video {
        width:300px;
    }
    
    .post-content .pdf {
        width:300px;
    }
}