
* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
}

body, html {
  /* min-width: fit-content; */
  /* width: 100%;
  height: auto;
  background-color: #eee; */
}

input[type="submit"] {
  border-radius: 8px !important;
}

.order-btn {
  height: 40px;
  cursor: pointer;
  font-weight: 700;
  /* background-color: #F06977; */
  border: none;
  transition: all ease .3s;
  border-radius: 16px;
  width: 90%;
  font-size: 16px;
  padding: 5px 20px;
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 10px;
}



#order-complete .disabled {
  opacity: 0.4;
  cursor: no-drop;
}

#navbar {
  height: 100px;
  background-color: #fff;
  clear: both;
}

#navbar img {
  display: inline-block;
  margin: 20px;
  max-height: 50px;
  width: auto;
}

#navbar #loc-container {
  float: right;
  height: 100%;
  padding: 20px 100px 20px 0px;
}

#navbar #loc-container a {
  font-size: 16px;
  text-decoration: none;
  color: #000;
  transition: all ease .3s;
  display: inline-block;
}

#navbar #loc-container a:last-child {
  background-color: var(--main-color);
  color: var(--secondary-color);
  padding: 15px 20px;
  font-weight: 700;
  transition: all ease .3s;
  border-radius: 8px;
}

#navbar #loc-container a i{
  margin-left: 10px;
}

#navbar #loc-container a:last-child:hover {
  transform: scale(1.1);
}

#register-form {
  display: block;
  margin: 100px auto;
  padding: 25px 40px 25px 40px;
  max-width: 850px;
  background-color: #fff;
  border: none;
  border-radius: 12px;
  box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
}

#register-form .logo {
  width: 100px;
  border-radius: 100px;
}

#register-form h1 {
  font-size: 24px;
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  margin-bottom: 25px;
}



#register-form .left {
  text-align: left;
}

#register-form .right {
  text-align: right;
}


#register-form .container {
  padding-top: 25px;
  padding-bottom: 25px;
}


#register-form .col-lg-8 {
  border-right: 1px solid #E4E4E4;
  padding-left: 25px;
  padding-right: 50px;
}

#register-form .col-lg-4 {
  text-align: center;
  padding-left: 50px;
}

#register-form h2 {
  font-size: 18px;
  text-align: center;
}

#register-form p {
  font-size: 14px;
}

#register-form select, .input-field, #card-element {
  display: block;
  margin: 10px auto;
  width: 100%;
  height: auto;
  padding: 10px;
  outline: none;
  border-radius: 8px;
  border: 1px solid #DDDDE0;
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  color: #000;
}


#donate-form {
  display: block;
  margin: 80px auto;
  padding: 25px 40px 25px 40px;
  max-width: 650px;
  background-color: #fff;
  border: none;
  border-radius: 12px;
  box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
}

#donate-form .logo {
  width: 100px;
  border-radius: 100px;
}

#donate-form h1 {
  font-size: 24px;
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  margin-bottom: 25px;
}



#donate-form .left {
  text-align: left;
}

#donate-form .right {
  text-align: right;
}


#donate-form .container {
  padding-top: 25px;
  padding-bottom: 25px;
}


#donate-form .col-lg-8 {
  border-right: 1px solid #E4E4E4;
  padding-left: 25px;
  padding-right: 50px;
}

#donate-form .col-lg-4 {
  text-align: center;
  padding-left: 50px;
}

#donate-form h2 {
  font-size: 18px;
  text-align: center;
}

#donate-form p {
  font-size: 14px;
}

#donate-form select, .input-field, #card-element {
  display: block;
  margin: 10px auto;
  width: 100%;
  height: auto;
  padding: 10px;
  outline: none;
  border-radius: 8px;
  border: 1px solid #DDDDE0;
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  color: #000;
  resize: none;
}

.billing-field {
  margin-top: 3.5px !important;
  margin-bottom: 3.5px !important;
}
  

.form-control {
  outline: none !important;
  border: 1px solid #DDDDE0 !important;
  font-size: 16px !important;
  font-family: "Helvetica Neue", Helvetica, sans-serif !important;
  color: #000 !important;
  height: 45px !important;
}

.form-control::placeholder {
  color: #aab7c4 !important;
}


#card-errors {
  color: #F06977;
}

#tax-alert {
  /* color: #aab7c4; */
}

#coupon-alert, #tax-alert {
  margin-top: -10px;
  margin-bottom: 5px;
  font-size: 14px;
}

#trial-alert {
  margin-bottom: 5px;
  font-size: 14px;
}

.coupon-red {
  color: #F06977;
}

#register-form select:required:invalid {
  color: #aab7c4;
}

#register-form select option {
  color: #000;
}

#register-form input[type="submit"] {
  margin-top: 20px;
  font-weight: 700;
  background-color: var(--main-color);
  /* background-color: #F06977; */
  color: var(--secondary-color);
  border: none;
  transition: all ease .3s;
  border-radius: 16px;
}

#register-form input[type="submit"]:hover {
  cursor: pointer;
}

#register-form #membership::placeholder, input::placeholder {
  color: #aab7c4;
}

/*  */


#donate-form select:required:invalid {
  color: #aab7c4;
}

#donate-form select option {
  color: #000;
}

#donate-form input[type="submit"] {
  margin-top: 20px;
  font-weight: 700;
  background-color: var(--main-color);
  /* background-color: #F06977; */
  color: var(--secondary-color);
  border: none;
  transition: all ease .3s;
  border-radius: 16px;
}

#donate-form input[type="submit"]:hover {
  cursor: pointer;
}


#agreer {
  margin-top: 20px;
}

#checkmark {}

#agreement {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  color: #B2B6C3;
  font-size: 15px;
}

#agreement a {
  color: #4EA0BC;
}

#register-form h5 {
  font-size: 16px;
}


@media only screen and (max-width: 991px) {
  #register-form {
    width: 85%;
  }

  #donate-form {
    width: 75%;
  }

  .badge {
    margin-top: -15px;
    margin-bottom: 0px;
  }
  #register-form .col-lg-8 {
    border-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 25px;
    border-bottom: 1px solid #E4E4E4;
  }

  #register-form .col-lg-4 {
    text-align: center;
    padding-left: 0px;
    margin-top: 25px;
  }

  #product-name {
    margin-top:-15px;
  }

  .subtotal-row {
    margin-top: -15px !important;
  }

  .logo-row {
    margin-top: 15px !important;
  }
    
}



/* DASHBOARD */
.sideshow {
  background-color: var(--main-color);
  padding: 20px;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  position: fixed;
  left: -5px;
  top: 40%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all ease .32s;
  display: none;
  z-index: 1;
}

.sideshow i {
  color: var(--secondary-color);
  font-size: 24px;
}

@media only screen and (min-width: 992px) {
  #wrapper {
    max-width: 1500px;
    margin: auto;
  }
}
@media only screen and (max-width: 992px) {
  .mt-4 {
    text-align: center;
  }

  #sidebar-wrapper {
    margin-left: -15rem;
  }

  #page-container {
    margin-left: 0px !important;
  }

  .sideshow {
    display: block !important;
  }

  #key-box {
    margin: auto;
    margin-top: 20px;
  }

  .sub-box {
    margin: auto;
    margin-top: 20px;
  }

  #ref-box {
    margin: auto;
    margin-top: 20px;
  }

  #billing-box {
    margin: auto;
    margin-top: 20px;
  }

  #discord-welcome {
    margin: auto;
    margin-top: -15px;
  }

}

#page-container {
  margin-left: 300px;  
  padding-top: 25px;
} 

.mt-4 {
  font-size: 28px;
  font-weight: 700;
  color: #11142D !important;
  margin-bottom: 30px;
}

#discord-welcome {
  max-width: 700px;
  min-width: 250px;
  display: block;
  margin-top: -15px;
  /* margin: auto; */
  /* margin-top:20px; */
  background-color: #fff;
  border: 1px solid #DDDDE0;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  text-align: center;
  padding: 20px;
  border-radius: 12px;
}

#discord-welcome h1 {
  font-size: 20px;
  font-weight: 700;
}

#discord-welcome img {
  border-radius: 50%;
  width: 75px;
  margin: 0px 0px 10px 0px;
}

#discord-welcome input {
  height: 40px;
  width: 90%;
  margin-top: 20px;
  background-color: var(--main-color);
  color: var(--secondary-color);
  border: none;
  transition: all ease .3s;
  transform: translateZ(0);
}

#discord-welcome input:hover {
  transform: scale(1.03);
  cursor: pointer;
}

.sub-box {
  max-width: 700px;
  min-width: 250px;
  display: block;
  background-color: #fff;
  border: 1px solid #DDDDE0;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  text-align: center;
  padding: 20px;
  border-radius: 12px;
  /* margin: auto; */
  margin-top:20px;
}

.sub-box h1 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.sub-box p {
  font-size: 16px;
  margin-bottom: 5px;
}

.green {
  color: var(--main-color) !important;
}

.red {
  color: #E94B52 !important;
}

.cancel {
  color: #fff !important;
}

.sub-box input[type="submit"] {
  height: 40px;
  width: 90%;
  margin-top: 20px;
  background-color: var(--main-color);
  color: var(--secondary-color);
  border: none;
  transition: all ease .3s;
  transform: translateZ(0);
}

.sub-box input[type="submit"]:hover {
  transform: scale(1.03);
  cursor: pointer;
}


#billing-box {
  max-width: 700px;
  min-width: 250px;
  display: block;
  /* margin: auto; */
  margin-top:20px;
  background-color: #fff;
  border: 1px solid #DDDDE0;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  text-align: center;
  padding: 20px;
  border-radius: 12px;
}

#billing-box h1 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

#billing-box p {
  font-size: 16px;
  margin-bottom: 5px;
}

#billing-box input {
  width: 90%;
  height: 40px;
}

#billing-box input::placeholder {
  color: #aab7c4;
}

#billing-box input[type="submit"] {
  width: 90%;
  margin-top: 20px;
  background-color: var(--main-color);
  color: var(--secondary-color);
  border: none;
  transition: all ease .3s;
  transform: translateZ(0);
}

#billing-box input[type="submit"]:hover {
  transform: scale(1.03);
  cursor: pointer;
}

.cancel {
  width: 90%;
  margin-top: 20px;
  background-color: #E94B52 !important;
  color: #ffffff;
  border: none;
  transition: all ease .3s;
  transform: translateZ(0);
}



.cancel:hover {
  transform: scale(1.03);
  cursor: pointer;
}

#key-box {
  max-width: 700px;
  min-width: 250px;
  display: block;
  /* margin: auto; */
  margin-top:20px;
  background-color: #fff;
  border: 1px solid #DDDDE0;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  text-align: center;
  padding: 20px;
  border-radius: 12px;
}

#key-box h1 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

#key-box select {
  display: block;
  margin: 10px auto;
  width: 90%;
  height: auto;
  padding: 10px;
  outline: none;
  border: 1px solid #DDDDE0;
  font-size: 16px;
  border-radius: 8px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  color: #000;
}

#key-box select:required:invalid {
  color: #aab7c4;
}

#key-box select option {
  color: #000;
}

#key-box input {
  width: 90%;
  text-align: center;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #DDDDE0;
}

#key-box input[type="submit"] {
  width: 90%;
  margin-top: 20px;
  background-color: var(--main-color);
  color: var(--secondary-color);
  border: none;
  transition: all ease .3s;
  transform: translateZ(0);
}

#key-box input[type="submit"]:hover {
  transform: scale(1.03);
  cursor: pointer;
}


#ref-box {
  max-width: 700px;
  min-width: 250px;
  display: block;
  /* margin: auto; */
  margin-top:20px;
  background-color: #fff;
  border: 1px solid #DDDDE0;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  text-align: center;
  padding: 20px;
  border-radius: 12px;
}

#ref-box h1 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

#ref-box select {
  display: block;
  margin: 10px auto;
  width: 90%;
  height: auto;
  padding: 10px;
  outline: none;
  border: 1px solid #DDDDE0;
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  color: #000;
}

#ref-box select:required:invalid {
  color: #aab7c4;
}

#ref-box select option {
  color: #000;
}

#ref-box input {
  width: 90%;
  text-align: center;
  height: 40px;
  border: 1px solid #DDDDE0;
}

#ref-box input[type="submit"] {
  width: 90%;
  margin-top: 20px;
  background-color: var(--main-color);
  color: var(--secondary-color);
  border: none;
  transition: all ease .3s;
  transform: translateZ(0);
}

#ref-box input[type="submit"]:hover {
  transform: scale(1.03);
  cursor: pointer;
}


.box {
  max-width: 700px;
  min-width: 250px;
  display: block;
  /* margin: auto; */
  margin-top:20px;
  background-color: #fff;
  border: 1px solid #DDDDE0;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  text-align: center;
  padding: 20px;
  border-radius: 12px;
}

.box h1 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.upgrade-box {
  margin-top: 30px;
}

.upgrade-box h1 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.upgrade-box select {
  display: block;
  margin: 10px auto;
  width: 90%;
  height: auto;
  padding: 10px;
  outline: none;
  border: 1px solid #DDDDE0;
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  color: #000;
}

.upgrade-box select:required:invalid {
  color: #aab7c4;
}

.upgrade-box select option {
  color: #000;
}

.upgrade-box input {
  width: 90%;
  text-align: center;
  height: 40px;
  border: 1px solid #DDDDE0;
}

.upgrade-box input[type="submit"] {
  width: 90%;
  margin-top: 20px;
  background-color: var(--main-color);
  color: var(--secondary-color);
  border: none;
  transition: all ease .3s;
  transform: translateZ(0);
}

.upgrade-box input[type="submit"]:hover {
  transform: scale(1.03);
  cursor: pointer;
}

#addon-box {
  max-width: 700px;
  min-width: 250px;
  display: block;
  /* margin: auto; */
  margin-top:20px;
  background-color: #fff;
  border: 1px solid #DDDDE0;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  text-align: center;
  padding: 20px;
  border-radius: 12px;
}

#addon-box h1 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

#addon-box select {
  display: block;
  margin: 10px auto;
  width: 90%;
  height: auto;
  padding: 10px;
  outline: none;
  border: 1px solid #DDDDE0;
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  color: #000;
}

#addon-box select:required:invalid {
  color: #aab7c4;
}

#addon-box select option {
  color: #000;
}

#addon-box input {
  width: 90%;
  text-align: center;
  height: 40px;
  border: 1px solid #DDDDE0;
}

#addon-box input[type="submit"] {
  width: 90%;
  margin-top: 20px;
  background-color: var(--main-color);
  color: var(--secondary-color);
  border: none;
  transition: all ease .3s;
  transform: translateZ(0);
}

#addon-box input[type="submit"]:hover {
  transform: scale(1.03);
  cursor: pointer;
}

/* Modals */
.modal-hidden {
  display: none !important;
}

.data-modal {
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 3000;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.data-modal .modal-content {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 100px;
  height: 344px;
  width: 300px;
  background-color: #FFF;
  border: 1px solid #EBEDF5;
}

.data-modal .modal-header {
  height: 60px;
  border-bottom: 1px solid #EBEDF5;
  text-align: center;
}

.data-modal .modal-header span {
  position: absolute;
  right: 20px;
  margin-top: -20px;
  color: #A5ADC6;
  font-size: 18px;
  cursor: pointer;
  z-index: 4000;
}

.data-modal .modal-header h2 {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 5px;
  font-size: 15px;
  color: #3C4154;
}

.data-modal .modal-content {
  text-align: center;
}


.data-modal .modal-content .no-bottom {
  border-bottom: none;
}

.data-modal .modal-content input {
  width: 230px;
  height: 40px;
  padding-left: 10px;
  outline: none;
  border: 1px solid #EBEDF5;
  font-size: 14px;
  margin-top: 20px;
}

.data-modal .modal-content select {
  width: 250px;
  height: 40px;
  padding-left: 10px;
  outline: none;
  border: 1px solid #EBEDF5;
  font-size: 14px;
  margin-top: 20px;
}

.data-modal .modal-content input::placeholder {
  color: #A5ADC6;
}

.data-modal .modal-content textarea::placeholder {
  color: #A5ADC6;
}

.data-modal img {
  width: 150px;
  height: 150px;
}

.card-actions {
  padding-top: 0px;
  padding-left: 15px;
  text-align: left;
}

.card-actions a {
  font-size: 12px;
  cursor: pointer;
  text-decoration: none;
  margin-right: 5px;
}

.card-actions a:hover {
  text-decoration: underline;
}

#remove-card {
  margin-left: 5px;
}

.edit-new #cardElement {
  display: block;
  margin: 10px auto;
  width: 100%;
  height: auto;
  padding: 10px;
  outline: none;
  border-radius: 8px;
  border: 1px solid #DDDDE0;
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  color: #000;
}

/*
.card-details {
  border-top: none !important;
  border-bottom: none !important;
  width: 107.1px !important;
}
#card-exp {
  border-right: none;
}
#cvv {
  border-left: none;
  padding-left: none;
} */
.data-modal .modal-content textarea {
  width: 220px;
  height: 115px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  border: 1px solid #EBEDF5;
  font-size: 14px;
  resize: none;
}

.data-modal .modal-footer {
  bottom: 0;
  height: 50px;
  width: 100%;
  border-top: 1px solid #EBEDF5;
  text-align: center;
}

.data-modal .modal-footer a {
  margin: auto;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}


.data-modal .modal-footer-2 {
  bottom: 0;
  height: 50px;
  width: 100%;
  border-top: 1px solid #EBEDF5;
  text-align: center;
}

.data-modal .modal-footer-2 a {
  margin: auto;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}


#order-complete {
  display: block;
  margin: 100px auto;
  padding: 20px;
  max-width: 700px;
  min-width: 250px;
  background-color: #fff;
  text-align: center;
  border: none;
  border-radius: 12px;
  box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
}

#order-complete h1 {
  font-size: 20px;
  margin: 20px;
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
}

#order-complete h2 {
  font-size: 18px;
  margin: 20px 0px 10px 0px;
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
}

#order-complete p {
  font-size: 16px;
  margin-bottom: 5px;
}

#order-complete input {
  width: 90%;
  font-size: 16px;
  padding: 5px 20px;
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 10px;
}

#order-complete i {
  font-size: 16px;
  margin: 10px 5px;
}

#order-complete input[type="submit"] {
  height: 40px;
  cursor: pointer;
  font-weight: 700;
  background-color: var(--main-color);
  /* background-color: #F06977; */
  color: var(--secondary-color);
  border: none;
  transition: all ease .3s;
  border-radius: 16px;
}


@media only screen and (max-width: 550px) {
  #navbar #loc-container {
    padding: 20px 50px 20px 0px;
  }
}

.bootbox .modal-header {
  display: none !important;
}

.bootbox .modal-content {
  border-radius: 8px;
}

.bootbox .btn.btn-primary {
  background-color: var(--main-color) !important;
  color: var(--secondary-color) !important;
}

.bootbox .btn-success {
  background-color: var(--main-color) !important;
  color: var(--secondary-color) !important;
  border-color: var(--main-color) !important;
  outline: none !important;
}

.bootbox button:focus {
    box-shadow: none !important;
}

.bootbox .btn-danger {
  background-color: #E94B52 !important;
  color: #fff;
  border-color: #E94B52 !important;
}

.bootbox .bootbox-cancel {
  background-color: #E94B52 !important;
  color: #fff;
  border-color: #E94B52 !important;
}

.bootbox-accept:active {
  border-color: transparent !important
}

.btn {
  border-radius: 0px !important;
}

.modal-title {
  float: left !important;
  font-size: 16px !important;
}

.bootbox-close-button {
  position: absolute;
  right: 25px;
}

.watermark {
  color: var(--secondary-color);
  font-size: 14px;
  font-style: italic;
}

.watermark:hover {
  color: var(--secondary-color);
  text-decoration: none;
}

#wrapper {
  overflow-x: hidden;
  overflow-y: hidden;
}

/* SIDEBAR */


#wrapper.toggled #sidebar-wrapper {
  margin-left: 0px;
}

#sidebar-wrapper .mobile {
  display: none;
}

/* Sidebar */
#sidebar-wrapper {
  background-color: #fff;
  z-index: 3;
  background-size: cover;
  min-height: 100vh;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
  position: fixed;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  font-family: 'Open Sans', sans-serif;
  max-width: 242px !important;
  border-right: 1.5px solid #dee2e6!important;
}

a {
  cursor: pointer;
  text-decoration: none !important;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
  color: #5a5c69;
}

#sidebar-wrapper .list-group {
  min-width: 15rem;
  width: auto;
  background-color: transparent !important;
  color: #FFF;
}

#sidebar-wrapper .list-group hr {
  width: 75%;
  display: block;
  margin: 0 auto;
  margin-top: -5px;
  background-color: rgba(255, 255, 255, .5);
}

#sidebar-wrapper::-webkit-scrollbar-track {
  background-color: #E3E6EC;
  border-radius: 4px;
}

#sidebar-wrapper::-webkit-scrollbar {
  width: 4px;
  background-color: #F5F5F5;
}

#sidebar-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--main-color) !important;
  border-radius: 4px;
}

#sidebar-logo {
  border: 1px solid #E4E4E4 !important;
  /* box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important; */
}

.active-item span {
  color: var(--main-color) !important;
}

.active-item>svg>path {
  fill: var(--main-color) !important;
}


#nav-logo {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-position: center;
  background-size: cover;
  display: block;
  margin: 0 auto;
}

.user-corner-panel {
  position: absolute;
  right: -25px;
  top: -12.5px;
  padding: 40px;
  z-index: 5;
}

.user-corner-panel img {
  width: 50px;
  height: 50px;
  vertical-align: middle;
}

.user-corner-panel-text {
  height: 50px;
  float: right;
  font-size: 16px;
}

.user-corner-panel-text span:first-child {
  font-size: 16px;
  color: #11142D;
  font-weight: 700;
}

.user-corner-panel-text span:nth-child(3) {
  color: #808191;
  font-weight: 600;
  font-size: 16px;
}

.help-dropdown {
  position: absolute;
  left: -27.5px;
  top: 105px;
  padding: 20px 30px;
  border-radius: 12px;
  background-color: #fff;
  text-align: center;
  box-shadow: 0px 0px 60px -30px rgba(0, 0, 0, 0.75);
  transition: all ease 0.3s;
}

.help-dropdown::before {
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  position: absolute;
  border-bottom: 20px solid #fff;
  border-top: 20px solid transparent;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}

.help-dropdown ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.help-dropdown li:not(:last-child) {
  margin: 0px 0px 16px 0px;
  padding: 0px;
}

.help-dropdown li a {
  text-decoration: none;
  color: #808191;
  font-weight: 700;
  font-size: 13px;
  margin: 0px;
  transition: all ease 0.3s;
}

.help-dropdown li a:hover {
  color: var(--main-color) !important;;
}

#sidebar-wrapper .list-group-item {
  border: none;
  border-radius: 12px;
  background-color: transparent;
  margin: 0px 20px 0px 20px;
  /* padding: 18px 45px 18px 20px; */
  color: #808191;
  transition: all ease 0.3s;
  font-size: 14px;
  font-weight: 600;
}

#sidebar-wrapper .list-group-item:nth-child(7) {
  margin-top: 15px;
}

#sidebar-wrapper .list-group-item:nth-child(5) {
  margin-bottom: 15px;
}

#sidebar-wrapper .list-group-item i {
  margin-right: 10px;
  color: #808191;
  transition: all ease 0.3s;
}


#sidebar-wrapper .list-group-item i:nth-child(2) {
  float: right;
  text-align: center;
  line-height: 28px;
}

#sidebar-wrapper .list-group-item:hover>span {
  color: #666774;
}

#sidebar-wrapper .list-group-item:hover>svg>path {
  fill: #666774;
}


#cancel-form {
  padding-top: 30px;
}

#cancel-form select {
  margin-bottom: 15px;
}

#cancel-form .bootbox-input-textarea {
  resize: none;
  height: 150px !important;
}

fdropdown-menu {
  min-height: 60px !important;
}


.input-group-text {
  height: 100%;
  border-radius: 0px !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}



/* NEW STYLING FOR THE CHECKOUT INFO LIST */
.info {
  margin-top: 15px;
  padding-left:15px;
  font-size: 16px;
  color: #808191;
  font-weight: 500;
  text-align: left;
}

