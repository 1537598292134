#loggedout-form {
    display: block;
    margin: 100px auto;
    padding: 40px 40px 40px 40px;
    max-width: 950px;
    min-height: 450px;
    background-color: #fff;
    border: none;
    border-radius: 12px;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 991px) {
    #loggedout-form {
        width: 85%;
    }
}


#loggedout-form img {
    display: block;
    /* margin: 0 auto; */
    /* width: 80px; */
    height: 80px;
    border-radius: 12px;
    border: 1px solid #e3e6f0;
    margin-right: 8px;
}

#loggedout-form .col-md-6 {
    padding: 0px;
    padding-right: 30px;
    /* padding-left: 15px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#loggedout-form .col-md-6:nth-child(2) {
    border-left: 1px solid #e3e6f0;
    padding-left: 30px;
    padding-right: 0px;
    /* align-items: center; */
}

#loggedout-form .flex-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

#loggedout-form .product {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#loggedout-form h5 {
    font-size: 14px;
    margin: 0px;
}

#loggedout-form h1 {
    font-size: 28px;
    margin: 0px;
    font-weight: bold;
}

#loggedout-form .interval {
    margin: 0px;
    font-size: 14px;
    color: #6c757d;
}

#loggedout-form p {
    margin: 0px;
    margin-top: 15px;
    font-size: 12px;
    color: #6c757d;
    font-weight: 500;
}


#loggedout-form .pricing {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 15px; */
    border: 1px solid #dddde0;
    border-radius: 8px;
    width: 100%;
    padding: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
}




#loggedout-form .pricing label {
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    color: #6c757d;
}

#loggedout-form .pricing p {
    margin: 0px;
    font-weight: 500;
    font-size: 14px;
    color: #000;
}

#loggedout-form .discord-button {
    background-color: rgb(89, 109, 226);
    color: #fff;
    border: none;
    border-radius: 8px;
    margin-top: 15px;
    padding: 12px;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}

#loggedout-form .discord-button:hover {
    opacity: 0.7;
}

@media screen and (max-width: 768px) {
    #loggedout-form .col-md-6:nth-child(2) {
        margin-top: 30px;
    }

    #loggedout-form .col-md-6:nth-child(2) {
        border-left: none
    }

}