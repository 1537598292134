.catalogue {
    /* width: min-content; */
    border: 1px solid;
    border-radius: 12px;
    cursor: pointer;
    margin-right: 15px;
    text-align: center;
    /* padding-top: 15px; */
    padding: 15px;
}

.card-img {
    height: 100%;
}

.card-img-overlay {
    text-align: center;
}

.catalogue .card-title {
    font-size: 14px;
    font-weight: bold;
    color: var(--main-color) !important;
}

.catalogue .card-text {
    font-size: 12px;
    font-weight: 500;
    color: var(--main-color) !important;
}